<template>
  <section class="typical-page-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8">
          <h1 class="typical-title">Privacy policy</h1>

          <div class="typical-content">
            <h2>Resume-Perfect.com</h2>
            <h2>Effective Date: May 2022</h2>
            <h2>PRIVACY POLICY IN GENERAL</h2>

            <p>
              This document governs the privacy policy of our website
              www.Resume-Perfect.com.
            </p>

            <p>
              Our privacy policy tells you what Personally Identifiable
              Information (PII) and Nonpersonal Information (NPI) we may collect
              from you, how we collect it, how we protect it, how we may share
              it, how you can access and change it, and how you can limit our
              sharing of it. Our privacy policy also explains certain legal
              rights that you have with respect to your PII. Any capitalized
              terms not defined herein will have the same meaning as where they
              are defined elsewhere on our website.
            </p>

            <p>
              Resume-Perfect.com (for convenience, we refer to
              Resume-Perfect.com as “we” or “us” or “our”) respects the privacy
              of our users (referred to as “user” or “you”), that access and use
              this Site for the creation of resumes and cover letters, along
              with features for distributing them, and other services (the “Site
              services”). The Site and its services are accessed through
            </p>

            <p>
              www.Resume-Perfect.com., and certain other internet locations
              (collectively, the “Site”). Our privacy policy (“Privacy Policy”)
              advises you and other users of the Site’s services, and about the
              information we may gather about or collect from you when you use
              of the Site, and its services, and how we use and disclose such
              information. Each time you use the Site services, the most recent
              version of this Privacy Policy will apply to your access and use,
              and you should regularly review any changes since the last time
              you used the Site.
            </p>

            <p>
              By accessing the Site, you transfer personal information to us,
              and by using the Site, you consent to that transfer and use of
              your personal information in accordance with this Privacy Policy.
              You also agree to abide by all applicable laws concerning your use
              of the Site and your agreements with us. Any persons accessing our
              Site from any jurisdiction with laws or regulations governing the
              use of the Internet, including personal data collection, use and
              disclosure that are at variance from those of the United States,
              the United Kingdom, Australia and countries with similar laws,
              should only use the Site in a manner lawful in their jurisdiction.
              If your use of our Site would be unlawful in your jurisdiction,
              please do not use the Site.
            </p>

            <p>
              NOTE: BY ACCESSING THIS SITE, OR BY NAVIGATING THE SITE, AND BY
              USING ITS SERVICES, INCLUDING BY CREATING AN ACCOUNT, YOU ACCEPT
              THIS PRIVACY POLICY, ITS PRACTICES AND POLICIES, AND ANY UPDATED
              VERSION POSTED ON THIS SITE.
            </p>

            <h2>DEFINITIONS</h2>

            <p>
              ‘Nonpersonal Information’ (NPI) is information that is in no way
              personally identifiable. Some NPI is obtained automatically when
              you access our website with a web browser. ‘Personally
              Identifiable Information’ (PII) is information that is personally
              identifiable to you and obtained for us to provide you with a
              product or service. PII may include information such as your name,
              email address, physical address, phone number, credit card
              information, and other information you provide to us.
            </p>

            <h3>Topics Covered in Our Privacy Policy</h3>
            <ul>
              <li>• Site Registration</li>
              <li>• Information We Collect</li>
              <li>• Our Use of Cookies</li>
              <li>• How Your Information Is Used</li>
              <li>• Retaining and Destroying Your PII</li>
              <li>• Updating, Requesting, and Deleting Your PII</li>
              <li>• Filing a Complaint With Authorities</li>
              <li>
                • Revoking Your Consent and Opting Out of Sharing Your
                Information
              </li>
              <li>• Objecting to Processing Your PII</li>
              <li>• Protecting the Privacy Rights of Third Parties</li>
              <li>• Do Not Track Settings</li>
              <li>• Links to Other Websites</li>
              <li>• Protecting Children’s Privacy</li>
              <li>• Our Email Policy</li>
              <li>• Our Security Policy</li>
              <li>• Use of Your Credit Card</li>
              <li>• International Data Transfers</li>
              <li>• Changes to Our Privacy Policy</li>
            </ul>

            <h2>OUR COLLECTION, USE AND DISCLOSURE OF USER INFORMATION</h2>

            <h3>Site Registration</h3>

            <p>
              Much of the user information we collect from and about users is
              provided by users when they open an account, such as a name,
              address, and email address. That “Identifying Information” such as
              a user’s name or email address, can be directly linked to or
              associated with a Site user. We may request additional information
              about your interests, your gender, job title, age, date of birth,
              username, hometown, country, and other information as determined
              from time to time.
            </p>

            <p>
              BY REGISTERING WITH OR USING THE SITE, YOU CONSENT TO THE USE AND
              DISCLOSURE OF YOUR IDENTIFYING INFORMATION AS DESCRIBED IN THIS
              SECTION.
            </p>

            <h2>INFORMATION WE COLLECT</h2>

            <p>
              Generally, you control the amount and type of information that you
              provide to us when using our website. We require that you provide
              us with certain information so that we can provide you with
              services and products. If you do not provide us with enough
              information, we may not be able to process your order. If you
              register as a user or member to use our website, you must provide
              PII for us to provide you with various features and functionality
              in terms of our website. We collect your PII in the following
              ways:
            </p>

            <h3>Automatic Information</h3>

            <p>
              We automatically receive information from your web browser or
              mobile device. This information includes the name of the website
              from which you entered our website, if any, as well as the name of
              the website you’ll visit when you leave our website. This
              information also includes the IP address of your computer/the
              proxy server you use to access the Internet, your Internet service
              provider’s name, your web browser type, the type of mobile device,
              and your computer operating system. We use all this information to
              analyze trends among our users to help improve our website.
            </p>

            <h3>When Entering and Using Our Website</h3>

            <p>
              When you enter and use our website and agree to accept cookies,
              some of these cookies may contain your PII.
            </p>

            <h3>When Buying Products or Services</h3>

            <p>
              If you buy products or services from us, we may collect some or
              all of the following information: your first, last, and middle
              name, email address, physical address, date of birth, city of
              birth, state or territory of birth, country of birth, credit card
              information, phone number, user name, password, sex, hair color,
              eye color, occupation, employer, school, and other information
              listed.
            </p>

            <h3>When You Create Resume and Cover Letter</h3>

            <p>
              Our product gives you the ability to create, edit, export and save
              PDF, DOCX, RTF, Txt files through your accounts. These files are
              stored in our server and you have full control when it comes to
              deleting these files along with the information contained in them.
              You can do so with the delete function in our customer dashboard.
              Once a form is deleted, the information contained in the forms are
              also deleted from our servers.
            </p>

            <p>
              Any information that you have put in the forms are not accessible
              in any way other than those forms. This information cannot be
              retrieved or accessed in any way other than when viewing the forms
              through your account dashboard. We do not use the information in
              your forms and are strictly for your access.
            </p>

            <h2>OUR USE OF COOKIES</h2>

            <p>
              Our website uses cookies. A cookie is a small piece of data or a
              text file that is downloaded to your computer or mobile device
              when you access certain websites. Cookies may contain text that
              can be read by the web server that delivered the cookie to you.
              The text contained in the cookie generally consists of a sequence
              of letters and numbers that uniquely identify your computer or
              mobile device; it may contain other information as well.
            </p>

            <p>
              By agreeing to accept our use of cookies, you are giving us
              permission to place some or all of the cookies described below on
              your device.
            </p>

            <h3>Strictly Necessary Cookies</h3>

            <p>
              These cookies are necessary for proper functioning of the website,
              such as displaying content, logging in, validating your session,
              responding to your request for services, and other functions. Most
              web browsers can be set to disable the use of cookies. However, if
              you disable these cookies, you may not be able to access features
              on our website correctly or at all.
            </p>

            <h3>Performance Cookies</h3>

            <p>
              These cookies collect information about the use of the website,
              such as pages visited, traffic sources, users’ interests, content
              management, and other website measurements.
            </p>

            <h3>Functional Cookies</h3>

            <p>
              These cookies enable the website to remember a user’s choices –
              such as their language, user name, and other personal choices –
              while using the website. They can also be used to deliver
              services, such as letting a user make a blog post, listen to
              audio, or watch videos on the website.
            </p>

            <h3>Media Cookies</h3>

            <p>
              These cookies can be used to improve a website’s performance and
              provide special features and content. They can be placed by third
              parties who provide services to us or by our company.
            </p>

            <h3>Advertising or Targeting Cookies</h3>

            <p>
              These cookies are usually placed and used by advertising companies
              to develop a profile of your browsing interests and serve
              advertisements on other websites that are related to your
              interests. You will see less advertising if you disable these
              cookies.
            </p>

            <h3>Session Cookies</h3>

            <p>
              These cookies allow websites to link the actions of a user during
              a browser session. They may be used for a variety of purposes,
              such as remembering what a user has put in their shopping cart as
              they browse a website. Session cookies also permit users to be
              recognized as they navigate a website so that any item or page
              changes they make are remembered from page to page. Session
              cookies expire after a browser session; thus, they are not stored
              long term.
            </p>

            <h3>Persistent Cookies</h3>

            <p>
              These cookies are stored on a user’s device in between browser
              sessions, which allows the user’s preferences or actions across a
              site (or, in some cases, across different sites) to be remembered.
              Persistent cookies may be used for a variety of purposes,
              including remembering users’ choices and preferences when using a
              website or to target advertising to them. We may also use cookies
              for:
            </p>

            <ul>
              <li>
                • Identifying the areas of our website that you have visited
              </li>
              <li>• Personalizing content you see on our website</li>
              <li>• Our website analytics</li>
              <li>• Remarketing our products or services to you</li>
              <li>
                • Remembering your preferences, settings, and login details
              </li>
              <li>
                • Targeted advertising and serving ads relevant to your
                interests
              </li>
              <li>• Affiliate marketing</li>
              <li>• Allowing you to post comments</li>
              <li>• Allowing you to share content with social networks</li>
            </ul>

            <p>
              Most web browsers can be set to disable the use of cookies.
              However, if you disable cookies, you may not be able to access
              features on our website correctly or at all.
            </p>

            <h3>Web Beacons</h3>

            <p>
              We may also use a technology called web beacons to collect general
              information about your use of our website and your use of special
              promotions or newsletters. The information we collect by web
              beacons allows us to statistically monitor the number of people
              who open our emails. Web beacons also help us understand the
              behavior of our customers and visitors.
            </p>

            <h3>Google Remarketing</h3>

            <p>Why am I seeing ads by Google for products I’ve viewed?</p>

            <p>
              Our website uses a remarketing advertising service. Our
              remarketing service is provided by Google and other companies that
              show our ads on websites across the Internet. With remarketing you
              may see ads for our products you have previously looked at. As an
              example, suppose you visit a website that sells computers, but you
              do not buy a computer on your first visit to that website. The
              website’s owner might like to encourage you to revisit his/her
              site and buy a computer by showing you his/her ads again on other
              websites that you visit. We use remarketing for similar purposes,
              so you may see advertisements for our products as you search the
              Internet. For this to happen Google or other remarketing providers
              will read a cookie that is already in your browser, or they place
              a cookie in your browser when you visit our site or other sites
              using remarketing. (This can only happen if your browser is set to
              let it happen).
            </p>

            <p>
              You can opt out of Google’s use of cookies and remarketing at this
              link: https://support.google.com/ads/answer/2662922?hl=en or you
              opt out using the Network Advertising Initiative opt out page at:
              http://optout.networkadvertising.org/#!/
            </p>

            <h3>Facebook Remarketing</h3>

            <p>
              Our website uses Facebook’s remarketing advertising service. With
              remarketing, you may see ads for our products which you have
              previously looked at when you use Facebook. For this to happen
              Facebook uses a Custom Audience Pixel that is activated when a
              visitor lands on a webpage and a unique “cookie” is placed in
              their browser. To opt out of Facebook remarketing visit:
              https://www.facebook.com/help/568137493302217
            </p>

            <h3>Analytics Privacy Policy</h3>

            <p>
              Our website uses Analytics to collect information about the use of
              our site. Analytics collects information such as how often users
              visit our site, what pages they visit, and what other sites they
              have used prior to coming to our site. We also use the information
              we get from Analytics to improve our site.
            </p>

            <h2>HOW YOUR INFORMATION IS USED</h2>

            <p>We use the information we receive from you to:</p>

            <ul>
              <li>• Provide our products and services</li>
              <li>• Personalize and customize our content</li>
              <li>• Make improvements to our website</li>
              <li>• Contact you with updates to our website</li>
              <li>• Resolve problems and disputes</li>
              <li>
                • Contact you with marketing and advertising that we believe may
                be of interest to you
              </li>
            </ul>

            <h3>Communications and Emails</h3>

            <p>
              When we communicate with you about our website, we will use the
              email address you provided when you registered as a member or
              user. We may also send you emails with promotional information
              about our website or offers from us or our affiliates unless you
              have opted out of receiving such information. You can change your
              contact preferences at any time through your account or by sending
              us an email with your request to:
              <a href="mailto:support@resume-perfect.com"
                >support@resume-perfect.com</a
              >
            </p>

            <p>Sharing Information With Affiliates and Other Third Parties</p>

            <p>
              We do not sell your PII to third parties. However, we may share
              your PII with other parties as described in this privacy policy.
              For data aggregation purposes, we may use your nonpersonal
              information (NPI), which might be sold to other parties at our
              discretion. Any such data aggregation would not contain any of
              your PII.
            </p>

            <p>
              We may provide your PII to third-party service providers we hire
              to provide services to us. These third-party service providers
              include but are not limited to: payment processors, web analytics
              companies, call centers, data management services, help desk
              providers, shopping cart and email service providers, and shipping
              and mailing companies.
            </p>

            <p>
              At our discretion, we may share your PII with our affiliates,
              business partners, joint-venture partners, and other companies so
              they can send you promotions and offers about their services and
              products. Please understand that when we share your PII with other
              companies for marketing purposes, your PII becomes subject to the
              other company’s privacy policy.
            </p>

            <h3>Legally Required Releases of Information</h3>

            <p>
              We may be legally required to disclose your PII if such disclosure
              is (a) required by subpoena, law, or other legal process; (b)
              necessary to assist law enforcement officials or government
              enforcement agencies; (c) necessary to investigate violations of
              or otherwise enforce our Legal Terms; (d) necessary to protect us
              from legal action or claims from third parties, including you
              and/or other users or members; or (e) necessary to protect the
              legal rights, personal/real property, or personal safety of our
              company, users, employees, and affiliates.
            </p>

            <h3>Disclosures to Successors</h3>

            <p>
              If our business is sold or merges in whole or in part with another
              business that would become responsible for providing the website
              to you, we retain the right to transfer your PII to the new
              business. The new business would retain the right to use your PII
              according to the terms of this privacy policy as well as to any
              changes to this privacy policy as instituted by the new business.
            </p>

            <p>
              We also retain the right to transfer your PII if our company files
              for bankruptcy and some or all of our assets are sold to another
              individual or business.
            </p>

            <h2>RETAINING AND DESTROYING YOUR (PII)</h2>

            <p>
              We retain information that we collect from you (including your
              PII) only for as long as we need it for legal, business, or tax
              purposes. Your information may be retained in electronic form,
              paper form, or a combination of both. When your information is no
              longer needed, we will destroy, delete, or erase it.
            </p>

            <h2>UPDATING, REQUESTING, AND DELETING YOUR (PII)</h2>

            <p>
              You can update your PII using services found on our website. If no
              such services exist, you can contact us using the contact
              information found at the top of this policy and we will help you.
              You have the right to request and access the type of PII we have
              collected from you that is stored in electronic or paper format by
              our company. You also have the right to request that we delete any
              PII we have about you. If you want access to your PII, are
              requesting to know what PII we have collected about you, or are
              requesting that we delete your PII, send us an email with your
              request to:
              <a href="mailto:support@resume-perfect.com"
                >support@resume-perfect.com</a
              >
              or contact us using our contact information at the top of this
              website and we will help you. However, we may keep your PII as
              needed to enforce our agreements and to comply with any legal
              obligations.
            </p>

            <h2>FILING A COMPLAINT WITH AUTHORITIES</h2>

            <p>
              You have the right to file a complaint with supervisory
              authorities if your information has not been processed in
              compliance with the General Data Protection Regulation. If the
              supervisory authorities fail to address your complaint properly,
              you have the right to a judicial remedy.
            </p>

            <h2>
              REVOKING YOUR CONSENT AND OPTING OUT OF SHARING YOUR INFORMATION
            </h2>

            <p>
              You have the right to revoke your consent for us to use your PII
              at any time. You can always opt out of future unaffiliated
              third-party disclosures of your information. Such an optout will
              not affect disclosures otherwise permitted by law including but
              not limited to: (i) disclosures to affiliates, (ii) disclosures to
              third-party service providers that provide certain services for
              our business, such as credit card processing, computer system
              services, shipping, data management, or promotional services,
              (iii) disclosures to third parties as necessary to fulfill your
              requests, (iv) disclosures to governmental agencies or law
              enforcement departments, or as otherwise required to be made under
              applicable law, (v) previously completed disclosures to third
              parties, or (vi) disclosures to third parties in connection with
              subsequent contests or promotions you may choose to enter, or
              third-party offers you may choose to accept. If you want to opt
              out of unaffiliated third-party disclosures or revoke your consent
              for us to use your PII, send us an email with your request to:
              <a href="mailto:support@resume-perfect.com"
                >support@resume-perfect.com</a
              >
            </p>

            <h2>OBJECTING TO PROCESSING YOUR PII</h2>

            <p>You have the right to object to the processing of your PII.</p>

            <h2>PROTECTING THE PRIVACY RIGHTS OF THIRD PARTIES</h2>

            <p>
              If any postings you make on our website contain information about
              third parties, you must make sure you have permission to include
              that information in your posting. While we are not legally liable
              for the actions of our users, we will remove any postings about
              which we are notified, if such postings violate the privacy rights
              of others.
            </p>

            <h2>DO NOT TRACK SETTINGS</h2>

            <p>
              Some web browsers have settings that enable you to request that
              our website not track your movement within our website. Our
              website does not obey such settings when transmitted to and
              detected by our website. You can turn off tracking features and
              other security settings in your browser by referring to your
              browser’s user manual.
            </p>

            <h2>LINKS TO OTHER WEBSITES</h2>

            <p>
              Our website may contain links to other websites. These websites
              are not under our control and are not subject to our privacy
              policy. These websites will likely have their own privacy
              policies. We have no responsibility for these websites and we
              provide links to these websites solely for your convenience. You
              acknowledge that your use of and access to these websites are
              solely at your risk. It is your responsibility to check the
              privacy policies of these websites to see how they treat your PII.
            </p>

            <h2>PROTECTING CHILDREN’S PRIVACY</h2>

            <p>
              Even though our website is not designed for use by anyone under
              the age of 13 (child), we realize that a child under the age of 13
              may attempt to access our website. We do not knowingly collect PII
              from children under the age of 13. If you are a parent or guardian
              and believe that your child is using our website, please contact
              us. Before we remove any information, we may ask for proof of
              identification to prevent malicious removal of account
              information. If we discover that a child is accessing our website,
              we will delete his/her information within a reasonable period of
              time. You acknowledge that we do not verify the age of our users
              nor do we have any liability to do so.
            </p>

            <h2>OUR EMAIL POLICY</h2>

            <p>
              You can always opt out of receiving further email correspondence
              from us or our affiliates. We will not sell, rent, or trade your
              email address to any unaffiliated third party without your
              permission except in the sale or transfer of our business.
            </p>

            <h2>OUR SECURITY POLICY</h2>

            <p>
              We have built our website using industry-standard encryption and
              authentication tools to protect the security of your PII. We also
              use technologies such as secure routers and firewalls to help
              protect your PII. When we collect your credit card information
              through our website, we will encrypt it before it travels over the
              Internet using industry-standard technology for conducting secure
              online transactions.
            </p>

            <p>
              Unfortunately, we cannot guarantee against the loss or misuse of
              your PII or secure data transmission over the Internet because of
              its nature. We strongly urge you to protect any password you may
              have for our website and to not share it with anyone. You should
              always log out of our website when you finish using it, especially
              if you are sharing or using a computer in a public place.
            </p>

            <h2>USE OF YOUR CREDIT CARD</h2>

            <p>
              You may have to provide a credit card to buy products and services
              from our website. We use third-party billing services and have no
              control over these services. We use our commercially reasonable
              efforts to make sure your credit card number is kept strictly
              confidential by using only third-party billing services that use
              industry-standard encryption technology to protect your credit
              card number from unauthorized use. However, you understand and
              agree that we are in no way responsible for any misuse of your
              credit card number.
            </p>

            <h2>INTERNATIONAL DATA TRANSFERS</h2>

            <p>
              Information that we collect from you may be stored and processed
              in and transferred between any of the countries in which we
              operate. For European customers and users, your PII may be
              transferred outside the European Union for us to provide the
              services and information you request from us. Wherever we
              transfer, process or store your PII, we will take reasonable steps
              to protect it. We will use the information we collect from you in
              accordance with this policy. By using our website or services, you
              agree to the transfers of your PII described within this section.
              Also, PII you post on our website for publication may be available
              worldwide by means of the Internet. We cannot prevent the use or
              misuse of such information by others.
            </p>

            <h2>CHANGES TO OUR PRIVACY POLICY</h2>

            <p>
              We reserve the right to change this privacy policy at any time. If
              our company decides to change this privacy policy, we will post
              those changes on our website so that our users and customers are
              always aware of what information we collect, use, and disclose. If
              at any time we decide to disclose or use your PII in a method
              different from that specified at the time it was collected, we
              will provide advance notice by email (sent to the email address on
              file in your account). Otherwise we will use and disclose our
              users’ and customers’ PII in agreement with the privacy policy in
              effect when the information was collected. In all cases, your
              continued use of our website, services, and products after any
              change to this privacy policy will constitute your acceptance of
              such change.
            </p>

            <h2>CALIFORNIA PRIVACY RIGHTS</h2>

            <p>
              California Civil Code Section 1798.83 permits California resident
              users to request and obtain once per year, free of charge,
              information about any personal information we have disclosed to
              third parties for direct marketing purposes in the past calendar
              year. This information would include the categories of personal
              information shared, and the names and addresses of all third
              parties with which we shared information. California residents
              requesting such information, should submit the request to us in
              writing, and we will respond within the time allowed by law or a
              reasonable time.
            </p>

            <h3>California Consumer Privacy Act</h3>

            <p>
              As stated in the California Consumer Privacy Act (“CCPA”), if you
              are a California resident, you are provided specific additional
              rights. You have the right to request that businesses subject to
              the CCPA disclose certain information to you about their
              collection and use of your Personal Information over the past 12
              months. If www.Resume-Perfect.com reaches the minimum company
              requirements set by the CCPA for compliance, qualified residents
              can obtain from us, once a calendar year, Personal Information
              that was collected and shared, if any, with other businesses for
              their own direct marketing uses.
            </p>

            <p>
              California residents also has the right to request deletion of
              personal data, opt-out of the sales of their personal data and not
              be discriminated against if they choose to exercise any of these
              rights. If you would like to exercise your rights, you may contact
              us at
              <a href="mailto:privacy@resume-perfect.com"
                >privacy@resume-perfect.com</a
              >.
            </p>

            <h3>Questions About Our Privacy Policy</h3>

            <p>
              If you have any questions about our privacy policy, please email
              us at:
              <a href="mailto:support@resume-perfect.com"
                >support@resume-perfect.com</a
              >.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mixpanelMixin } from "@/mixins/MixPanelMixin";

export default {
  name: "Privacy Policy",
  mixins: [mixpanelMixin],

  created() {
    this.mixpanelTrack("V2-PRIVACYPOLICY");
  },
};
</script>

<style lang="scss" scoped>
section.typical-page-section {
  padding-top: 40px;
  padding-bottom: 100px;
}

.typical-title {
  text-align: center;
  margin-bottom: 24px;
}

.typical-content {
  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  p,
  h2,
  h3,
  ul {
    margin-bottom: 20px;
  }

  h3 {
    //margin-top: 40px;
  }

  a {
    color: $primary;
  }
}
</style>
